.DesktopOnly {
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
}

.MobileOnly {
  display: block;
  @media (min-width: 769px) {
    display: none;
  }
}

.Resolution {
  @media (min-width: 1024px) {
    max-width: 1440px;
    margin: auto;
  }
}
