@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap);
* {
  font-family: "Raleway", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-feature-settings: "lnum" 1;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.item{border-bottom:1px solid #E3E0E2;padding:12px 0}.Question{display:-ms-flexbox;display:flex}.collapsible{background-color:#fff;color:#002364;cursor:pointer;width:100%;border:none;text-align:left;outline:none;font-size:16px;padding:0;margin:0;font-weight:500;line-height:24px}.content{overflow:hidden;background-color:#fff;font-size:13px;margin-top:0px;transition:max-height 0.5s ease-in-out}.height-auto{transition:max-height 0.5s ease-in-out}.icon{transition:transform 0.5s ease-in-out;color:#002364}.rotate180{transition:transform 0.5s ease-in-out;-ms-transform:rotate(135deg);transform:rotate(135deg)}@media (min-width: 1024px){.Section_Collapse{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.row{display:-ms-flexbox;display:flex}.col1{width:49%}.col2{width:47%}.item{-ms-flex-positive:1;flex-grow:1;cursor:pointer}.collapsible{font-size:18px;line-height:27px}}

.DesktopOnly{display:none}@media (min-width: 769px){.DesktopOnly{display:block}}.MobileOnly{display:block}@media (min-width: 769px){.MobileOnly{display:none}}@media (min-width: 1024px){.Resolution{max-width:1440px;margin:auto}}

