.item {
  border-bottom: 1px solid #E3E0E2;
  padding: 12px 0;
}
.Question {
  display: flex;
}
.collapsible {
  background-color: #fff;
  color: #002364;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: 500;
  line-height: 24px;
}

.content {
  overflow: hidden;
  background-color: #fff;
  font-size: 13px;
  margin-top: 0px;
  // max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.height-auto {
  transition: max-height 0.5s ease-in-out;
}

.icon {
  transition: transform 0.5s ease-in-out;
  color: #002364;
}
.rotate180 {
  transition: transform 0.5s ease-in-out;
  transform: rotate(135deg);
}

@media (min-width: 1024px) {
  .Section_Collapse {
    display: flex;
    justify-content: space-between;
  }
  .row {
    display: flex;
  }
  .col1 {
    width: 49%;
  }
  .col2 {
    width: 47%;
  }
  .item {
    flex-grow: 1;
    cursor: pointer;
  }
  .collapsible {
    font-size: 18px;
    line-height: 27px;
  }
}
